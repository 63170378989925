body {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

@font-face {
  font-family: "HeadFont";
  src: url("./font/mtbold.woff");
}

@font-face {
  font-family: "Gel";
  src: url("./font/gel.ttf")
}

.geFont {
  font-family: "HeadFont";
}

.home-car-center > img {
  object-fit: cover;
}


.ant-switch.lang{
  /* background-color: #1890ff!important; */
  /* background-color: unset!important; */
  background-color: rgba(0, 0, 0, 0.25);
}

.ant-switch.lang img{
  display: block;
  width: 18px;
}

@media only screen and (max-width: 768px) {
  .ant-switch.lang img{
    width: 16px;
  }
  
}

/* logo color

blu: #25438B
red: #DA251D


background: linear-gradient(90deg, rgba(37,67,139,1) 0%, rgba(218,37,29,1) 70%);

*/

.top-header{
  position: relative;
  height: 110px!important;
  line-height: 108px!important;
  padding: 0!important;
  background: #fff!important;
  box-shadow: 0 1px 4px rgba(0,21,41,.08)!important;
}

/* @media only screen and (max-width: 768px) {
  .top-header{
    height: 50px!important;
    line-height: 48px!important;
  }
  
} */



.top-header .logo{
  display: block;
  text-align: center;

}

.top-header .logo img{
display: inline-block;
vertical-align: middle;
width: 100%;
/* height: 100px; */
}

.logo-name{
  text-align: right;
}

.fb_logo{
  width: 26px;
}


.tw_logo{
  width: 35px;
  margin-top: 1px;
}

@media only screen and (max-width: 768px) {
  .top-header .logo img{
    display: inline-block;
    width: 100%;
    height: auto;
    vertical-align: middle;
    }

    .top-header .logo-name img{
      display: inline-block;
      width: 100%;
      height: auto;
      vertical-align: middle;
      }


      .fb_logo{
        width: 20px;
      }
      
      
      .tw_logo{
        width: 27px;
      }

      .ant-segment .page-content .image img {
        width: 100%;
    }
    
}



.top-header-color1{
  background: linear-gradient(90deg, rgba(37,67,139,1) 0%, rgba(218,37,29,1) 70%)!important;
  height: 120px!important;
  line-height: 120px!important;
}

.top-header-color1 .ant-menu{
  line-height: 119px!important;
  background: transparent!important;
  color: #fff!important;
}

.top-header-color1 .logo img{
  height: 110px;
}

.mobilePage .slick-list .slick-slide{
  pointer-events: auto!important;
}


.mobilePageActive {
 /*  box-shadow: rgba(0, 21, 41, 0.3) inset 0 0 3px; */
  background-color: #e6f7ff;
}

.ant-segment{
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  margin: 1rem 0.5em;
  /* padding: 1em 1em; */
  /* padding: 0.5em 0.5em; */
  border-radius: .28571429rem;
  border: 1px solid rgba(34,36,38,.15);
}

.top-menu.ant-menu-horizontal > .ant-menu-submenu:hover{
  border-bottom: 2px solid #359cab;
}

/* .ant-menu-horizontal > .ant-menu-item:hover{
  color: #DA251D!important;
  border-bottom: 2px solid #DA251D!important;
} */

.top-menu .ant-menu-item,
.top-menu .ant-menu-submenu-title,
.side-bar-menu .ant-menu-item,
.side-bar-menu .ant-menu-submenu-title,
.page-title-ribbon-content,
.contact-info h3 {
  font-family: "HeadFont"!important;
}
.side-bar-menu .ant-menu-submenu-title a{
  color: rgba(0, 0, 0, 0.85);
}


.chapidzeBtn {
  background-color: #359cab !important;
  color: #fff !important;
}

.chapidzeBtn[disabled]{
  color: rgba(0, 0, 0, 0.25)!important;
  background-color: #f5f5f5!important;
  border-color: #d9d9d9!important;
  text-shadow: none!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
}

.chapidzeBtn-red {
  background-color: #c33 !important;
  color: #fff !important;
}

.hide {
  display: none;
}

.text-elips-200{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.page-content{
  padding: 1em 0.5em;
}


/* ribbon 1111111 */

.ribbon{
  position:relative;
  padding: 0.5em 0.5em;
  margin-top: 5px;
  font-size: 14px;
  /* margin: 0 0 0 -0.625em; */
  line-height: 1.875em;
  color: #e6e2c8;
  border-radius: 0 0.156em 0.156em 0;
  background: #2B395B;
  box-shadow: -1px 2px 3px rgba(0,0,0,0.5);
  font-weight: bold;
  color: #fff;
  width: 70%;
}



.ribbon:before, .ribbon:after{
  position:absolute;
  content: '';
  display: block;
  -webkit-box-sizing: unset!important;
  -moz-box-sizing: unset!important;
       box-sizing: unset!important;
}

.ribbon:before{
  width: 0.469em;
  height: 100%;
  padding: 0 0 0.438em;
  top:0;
  left: -0.469em;
  background:inherit;
  border-radius: 0.313em 0 0 0.313em;
}

.ribbon:after{
  width: 0.313em;
  height: 0.313em;
  background: rgba(0,0,0,0.35);
  bottom: -0.313em;
  left: -0.313em;
  border-radius: 0.313em 0 0 0.313em;
  box-shadow: inset -1px 2px 2px rgba(0,0,0,0.3);
}

@media (max-width: 600px) {
  
  .ribbon{
    line-height: 1.143em;
    padding: 0.5em;
  }
  
  .ribbon:before, .ribbon:after{
    font-size: 0.714em;
  }
  
}



/*  ribbon 22222 */

.page-title-ribbon {
  position: relative;
  overflow: hidden;
  max-height: 60px;
  margin-bottom: -8px;
  z-index: 1;
  padding: 12px 14px;
  border-radius: 2px 2px 0 0;
  color: #fff;
  text-align: left;
}
.page-title-ribbon:before, .page-title-ribbon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}


/* .page-title-ribbon:before {
  background: #a5daff;
  height: 100%;
  width: 110%;
  transform-origin: bottom right;
  transform: rotate(1deg);
}
.page-title-ribbon:after {
  background: #3fb0ff;
  transform: rotate(-3deg);
  transform-origin: bottom left;
  height: 100%;
  width: 110%;
} */


.page-title-ribbon-content {
  position: relative;
  z-index: 1;
  font-family: sans-serif;
  font-size: 18px;
}

.page-title-ribbon-content a{
  color: #fff;
}

.page-title-ribbon-content a:hover{
  color: #fff;
  border-bottom: 3px solid #fff;
}

.ribbon-color-1:before{
  background: linear-gradient(90deg, rgba(76,87,123,1) 0%, rgba(155,179,195,1) 70%);
  
}

.ribbon-color-1:after{
  background: linear-gradient(90deg, rgba(76,87,123,1) 0%, rgba(155,179,195,1) 70%);
}



.news-card{
  padding: 5px;
/*   height: 160px;
  line-height: 160px; */
}

.contact-info .contact-info-col-icon{
  width: 12%;
  float: left;
}

.contact-info .contact-info-col-content{
  width: 88%;
  float: left;
}

.contact-info p,
.contact-info h3{
  margin: 0;
}

.prtBtn{
  position: absolute!important;
  top: 50%!important;
  display: block!important;
  margin-top: -10px!important;
}

.ant-carousel .prtBtn-next {
  right: -35px;
}

.ant-carousel .prtBtn-prev {
  left: -35px;
}

.ant-list .ant-list-pagination{
  margin-bottom: 24px;
}

.category-list .category-font-size{
  color: #fff;
}
.category-list .category-font-size:hover{
  color: #fff;
  border-bottom: 2px solid #fff;
}

/* .category-font-size{
  font-size: 16px;
}
 */
@media
only screen and (max-width: 500px),
(min-device-width: 500px) and (max-device-width: 500px)  {

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr { /* border: 1px solid #ccc; */ padding-bottom: 10px; }

    td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        /* padding-left: 50%; */
    }

    td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }

    
    /* Label the data
    
    td:nth-of-type(1):before {content: "First Name"; 
        text-align: left;
        font-size: 12px;
    }
    td:nth-of-type(2):before {  content: "Last Name"; 
        text-align: left;
        font-size: 12px;
    }
    td:nth-of-type(3):before {  content: "Email"; 
        text-align: left;
        font-size: 12px;
    } */
}

@media (max-width: 500px)
{
    td
    {
        /* font-size: 12px!important;
        text-align: left!important; */
        padding-right: 5px;
    }
}